import React from "react";
import classNames from "classnames";
import TextCircle from "@organisms/TextCircle";
import { ArticleCard } from "@molecules";
import { Container, Button } from "@atoms";

const ArticleGrid = ({ bgColor, articles, button, className: _className }) => {
  return (
    <section
      className={classNames(
        "py-16 md:py-32",
        {
          "bg-midnight": bgColor === "midnight",
        },
        _className
      )}
    >
      <div>
        <Container className="relative">
          <TextCircle name="watermarkInsights" position="left" transparent />
          <div className="relative flex flex-wrap justify-center gap-4">
            {articles?.map(article => (
              <ArticleCard
                key={article.uid}
                className="flex-grow md:flex-[0_1_calc(50%-.5rem)] lg:flex-[0_1_calc(33.33%-.67rem)]"
                {...article}
              />
            ))}
          </div>
          {button?.url && (
            <div className="mt-12 flex justify-center">
              <Button to={button.url}>{button.text || "View All"}</Button>
            </div>
          )}
        </Container>
      </div>
    </section>
  );
};

ArticleGrid.defaultProps = {
  bgColor: "midnight",
};

export default ArticleGrid;
